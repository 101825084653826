.card-container {
  display: flex;
  flex-wrap: wrap;
}
.card {
  flex: 0 0 35rem;
  background-color: $color-blue-dark-2;
  padding: 2.5rem;
  border: $default-border;
  border-top: solid 0.5rem $color-blue-dark-1;
  transition: all 0.5s;
  justify-content: center;
  justify-items: center;
  align-items: center;
  @media (max-width: $bp-small) {
    align-items: center;
  }

  margin-bottom: 2rem;

  &:not(:last-child) {
    margin-right: 3rem;
  }
  &:hover {
    border-top: solid 0.5rem $color-blue;
  }
  &__icon {
    font-size: 4rem;
    color: $color-blue;
  }
  &__title {
    font-size: 1.8rem;
    padding: 1rem 0;
    color: $color-white;
    margin-bottom: 1.5rem;
    position: relative;

    &::after {
      position: absolute;
      bottom: -0.6rem;
      height: 0.3rem;
      width: 20%;
      border-radius: 20000px;
      left: 0;
      content: "";
      background-color: #2e344e;
    }
  }
}

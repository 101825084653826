.skills {
  position: relative;
  &::before {
    content: "SKILLS";
    position: absolute;
    top: 1.2rem;

    font-size: 8rem;
    letter-spacing: 1.2;
    opacity: 0.08;
  }
  &__heading {
    color: $color-white;
    font-weight: 400;
    margin-top: -3rem;
    font-size: 1.8rem;
  }
  &__list {
    display: flex;
    list-style: none;
    max-width: 70rem;
    flex-wrap: wrap;
    @media (max-width: $bp-small) {
      flex-direction: column;
    }
    justify-content: space-between;
    margin-top: 2rem;
  }
  &__item {
    flex: 0 0 40%;
    margin-bottom: 2rem;
    cursor: pointer;
    text-transform: uppercase;
    align-items: center;
    transition: all 0.5s;
    flex-wrap: wrap;

    span {
      padding-right: 1rem;
      color: $color-blue;
    }
    &:hover {
     
    }
  }
}
.span-icon {
  font-size: 2rem;

  padding-left: 0.5rem;
}

.education {
  position: relative;
  margin-top: 5rem;

  &::before {
    content: "Experience";
    position: absolute;
    top: 1.2rem;

    font-size: 8rem;
    letter-spacing: 1.2;
    opacity: 0.08;
  }
  &__card {
    width: 80%;
    display: flex;
    margin-left: 20%;
    border-left: 0.3rem solid $color-blue-dark-1;
    padding-left: 2rem;
    position: relative;
    z-index: 9;
    transition: all 0.5s cubic-bezier(1, 0, 0, 1);
    @media (max-width: $bp-small) {
      margin-left: 0;
      width: 100%;
    }
    &:hover {
      border-left: $color-blue solid 0.3rem;
    }
    &:hover::before {
      border: $color-blue solid 0.3rem;
      transition: all 0.5s cubic-bezier(1, 0, 0, 1);
    }
    &:not(:last-child) {
      padding-bottom: 5rem;
    }
    &::before {
      position: absolute;
      top: 1rem;
      left: 0;
      transform: translateX(-58%);
      height: 1.5rem;
      width: 1.5rem;
      border: 0.3rem solid $color-blue-dark-1;
      border-radius: 50%;
      content: "";
      z-index: 10;
      background-color: $color-blue-dark-3;
    }
  }
  &__time {
    flex: 0 0 30%;
    font-size: 2rem;
    font-weight: 400;
    text-transform: capitalize;
  }
  &__info {
    display: flex;
    flex-direction: column;
    width: 100%;

    @media (max-width: $bp-small) {
      margin-left: 3rem;
    }
  }
  &__title1 {
    font-size: 2rem;
    color: $color-blue;
    position: relative;

    &::before {
      content: "";
      height: 0.2rem;
      width: 3.6rem;
      background-color: $color-blue-dark-1;
      top: 1.7rem;
      position: absolute;
      transform: translateX(-5.5rem);
    }
  }
  &__title2 {
    font-size: 2rem;
    color: white;

    font-weight: 400;
  }
}

.project {
  position: relative;
  &::before {
    content: "PROJECTS";
    position: absolute;
    top: 1.2rem;

    font-size: 8rem;
    letter-spacing: 1.2;
    opacity: 0.08;
  }
  &__card {
    max-width: 37rem;
    width: 95%;
  }
  &__image {
    background-color: rgba($color: #000000, $alpha: 0.2);
    width: 100%;
    height: auto;
    position: relative;
    backface-visibility: hidden;
    overflow: hidden;
  }
  &__heading {
    color: $color-blue;
    font-size: 1.6rem;
    text-transform: capitalize;
    margin-top: 1.5rem;
    padding: 1rem;
  }
  &__text {
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    list-style: none;
    margin-top: -1.5rem;
    cursor: pointer;
    & > * {
      margin-top: 1.5rem;
    }
    span {
      padding-right: 0.5rem;
      color: $color-blue;
    }
  }

  &__photo {
    display: block;
    width: 100%;
    height: auto;
    cursor: pointer;
    overflow: hidden;
    backface-visibility: hidden;

    transform: scale(1);
    transition: filter, transform 0.4s;
    &:hover {
      transform: scale(1.1);
    }
  }
}
.card-project {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));
  grid-row-gap: 5rem;
  grid-column-gap: 3rem;
  width: 100%;
  justify-items: center;
}

body {
  font-family: "Nunito", sans-serif;
  font-size: $default-font-size;
  font-weight: 700;
  line-height: 1.7;
  color: $color-grey-dark;
}
.heading__primary {
  font-size: 5rem;
  color: $color-white;
  display: inline-block;
  &__span {
    color: $color-blue-light;
  }
}
.home__text {
  width: 80%;
  max-width: 128rem;
  font-size: 2rem;
  font-weight: 400;
  padding-bottom: 1.5rem;
  display: block;
  margin: auto;
}
.heading-3rd {
  font-weight: 600;
  font-size: 3rem;
  color: $color-white;
  text-transform: capitalize;
  span {
    color: $color-blue-light;
  }
}
.heading-secondary {
  font-weight: 700;
  font-size: 4rem;
  text-transform: uppercase;
  color: $color-white;
  position: relative;
  display: inline-block;
  padding-bottom: 2.5rem;
  margin-bottom: 3rem;
  &::before {
    width: 50%;
    background-color: $color-blue-dark-1;
    height: 0.5rem;
    content: "";
    position: absolute;
    bottom: 2rem;
    left: 0;
    border-radius: 10000;
  }
  &::after {
    width: 20%;
    background-color: $color-blue;
    height: 0.5rem;
    content: "";
    position: absolute;
    bottom: 2rem;
    left: 0;
    border-radius: 10000;
  }
}

.about {
  position: relative;
  &::before {
    content: "ABOUT ME";
    position: absolute;
    top: 1.2rem;

    font-size: 8rem;
    letter-spacing: 1.2;
    opacity: 0.08;
  }
  &__box {
    display: flex;
    @media (max-width: $bp-small) {
      flex-direction: column;
    }
  }
  &__image-container {
    margin-right: 5rem;
    flex: 0 0 50%;
    @media (max-width: $bp-small) {
      order: 2;
      margin-top: 4rem;
    }
    position: relative;
    z-index: 11;
  }
  &__photo {
    display: block;
    width: 100%;
  }
  &__text {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    & > * {
      margin-bottom: 2rem;
    }
    @media (max-width: $bp-small) {
      & > * {
        margin-bottom: 2rem;
      }
    }
  }
  &__paragraph {
    font-size: 1.7rem;
    font-weight: 400;
  }
  &__list {
    list-style: none;
    font-size: 1.7rem;
  }
  &__item {
    display: flex;
    text-transform: capitalize;
    font-weight: 400;
    &:not(:last-child) {
      margin-bottom: 1rem;
    }

    b {
      min-width: 12rem;
      position: relative;

      &::before {
        content: ":";
        position: absolute;
        right: 0;
        top: 0;
        transform: translateX(-1rem);
      }
    }
  }
}

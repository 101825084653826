.menu {
  flex: 1;
  display: flex;
}
.list {
  display: flex;
  flex-direction: column;
  list-style: none;
  justify-content: center;
  width: 100%;
  font-size: 1.6rem;

  &__item {
    display: inline-block;
    text-align: center;
    color: white;
    &:not(:last-child) {
      margin-bottom: 0.5rem;
    }

    position: relative;
  }
  &__item::before {
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    width: 3px;
    height: 100%;
    background-color: $color-blue;
    transform: scaleY(0);
    transform-origin: bottom;
    transition: transform 0.3s, width 0.4s cubic-bezier(1, 0, 0, 1) 0.3s;

    z-index: 10;
  }
  &__item:hover::before {
    transform: scaleY(1);
    width: 100%;
  }
  &__link {
    &:link,
    &:visited {
      text-decoration: none;
      text-transform: uppercase;
      color: inherit;
      z-index: 11;
      position: relative;
      display: inline-block;
      width: 100%;
      height: 100%;
      padding: 1rem 2rem;
    }
  }
}

.active {
  opacity: 0.6;
  background-color: $color-blue-dark-1;
 
}

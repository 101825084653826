.section-about,
.section-contact,
.section-skills,
.section-project,
.section-admin {
  padding: 10rem 2rem;
  max-width: 120rem;
  width: 100%;
  margin: auto;

  @media (max-width: $bp-smallest) {
    padding: 10rem 2rem !important;
  }
  @media (max-width: $bp-small) {
    padding: 10rem 6rem;
  }
}
